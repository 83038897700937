<template>
    <div class="definizione-obiettivi-di-rete">
        <div class="container-fluid full-height">
            <div class="row row-height">
                <div class="col-lg-4 content-left">
                    <div class="content-left-wrapper">
                        <!--            <a href="/" id="logo"><img src="../assets/logo_negativo.png" alt="" width="175"></a>-->
                        <SocialIcons></SocialIcons>
                        <div>
                            <figure><img src="../assets/logo_negativo.png" alt="" class="img-fluid"></figure>
                            <p class="mt-4">
                                Seleziona i tre obiettivi strategici delle attività della tua rete. <br>
                                In questo
                                modo il modello potrà offrirti una valutazione specifica in base agli obiettivi
                                principali e secondari.
                            </p>
                        </div>
                        <div class="copy">© 2020 RetImpresa Reference</div>
                    </div>
                </div>
                <!-- /content-left -->

                <div class="col-lg-8 content-right" id="start">
                    <div id="wizard_container" v-if="!showSelection">
                        <div id="middle-wizard">
                            <div class="step">
                                <h3 v-if="step === 0" class="main_question">Seleziona l'obiettivo di rete principale</h3>
                                <h3 v-if="step === 1" class="main_question">Seleziona il secondo obiettivo di rete</h3>
                                <h3 v-if="step === 2" class="main_question">Seleziona il terzo obiettivo di rete</h3>
<!--                                todo: contare a quale obiettivo siamo e quindi scrivere seleziona il secondo e il terzo-->
                                <div v-if="goals[0].id === 0" class="alert alert-danger">
                                    Siamo spiacenti, si è verificato un errore di comunicazione con il sito web. Ti invitiamo a riprovare più tardi.
                                </div>
                                <div v-else class="form-group" v-for="(q,idx) in goals" v-bind:key="idx">
                                    <label class="container_radio version_2">{{q.title}}
                                        <input
                                                type="radio"
                                                name="radio_input"
                                                v-bind:value="q.id"
                                                class="required"
                                                v-model="selected"
                                        >
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- /middle-wizard -->
                        <div id="bottom-wizard">
                            <button v-on:click="nextStep()" v-if="selected" class="forward mb-4">
                                Avanti
                                <span class="error error-goal" v-if="error">Seleziona un obiettivo</span>
                            </button>
                        </div>
                        <!-- /bottom-wizard -->
                    </div>
                    <div v-else>
                        <div class="summary">
                            <h2>Obiettivi selezionati</h2>
                            <p>Prima di procedere nella compilazione del modello, conferma gli obiettivi scelti</p>
                            <ul>
                                <li v-for="(goal, idx) in selection" v-bind:key="idx">
                                    <strong>{{ idx+1 }}</strong>
                                    <h5>{{ getGoalTitle(goal.id) }}</h5>
                                    <p v-if="goal.primary"> Obiettivo principale </p>
                                </li>
                            </ul>
                        </div>
                        <div id="bottom-wizard">
                            <button v-on:click="startSurvey" type="button" name="forward" class="forward">Inizia Survey</button>
                        </div>
                    </div>
                    <!-- /Wizard container -->
                </div>
                <!-- /content-right-->
            </div>
            <!-- /row-->
        </div>
        <!-- /container-fluid -->
    </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import SocialIcons from "@/components/SocialIcons";
  import * as _ from 'lodash';

  @Component({
    components: {
      SocialIcons
    },
  })

  export default class Survey extends Vue {
    goals = [];

    selection = [];

    selected = 0;

    step = 0;

    choices = 2

    error = false;

    showSelection = false;

    async mounted() {
      this.goals = this.$store.getters.getGoals;
    }

    /**
     * Metodo che imposta il principale obbiettivo di rete
     * @param id
     */
    setGoal(id) {
      this.selection.push({id: id, primary: (Boolean)(this.step === 0) })
      this.selected = null;
    }

    /**
     * Procede negli step di selezione degli obbiettivi
     * @return {null}
     */
    nextStep() {
      this.setGoal(this.selected);

      if(this.step >= this.choices)
        return this.lastStep();

      this.goals = _.filter(this.goals, (goal) => ! _.map(this.selection, 'id').includes(goal.id));

      this.step += 1;
    }

    /**
     * Review
     */
    lastStep() {
      this.showSelection = true;
    }

    /**
     * Recupera il titolo di un Goal dal suo ID
     * @param id
     * @return {*}
     */
    getGoalTitle(id) {
      return _.result(_.find(this.$store.getters.getGoals, {id: id}), 'title');
    }

    /**
     * Set Goal on state and start Survey
     */
    startSurvey() {
      this.$store.dispatch('setGoalsSelected', this.selection);
      this.$store.dispatch('startDescrizioneRete');
    }
  }
</script>

<style scoped>
    #wizard_container {
        height: 100%;
    }

    .error-goal {
        top: -26px !important;
        right: -44px !important;
    }

    /* Fix wrong button dimensions... */
    .forward:before {
        content: none;
    }
</style>
